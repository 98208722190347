<template>
    <div class="pagination">
        <router-link
            v-if="page != 1"
            :to="{path: path, query: queryParams(parseInt(page) - 1)}"
            class="page"
            v-on:click.native="scroll"
        >
        
        </router-link>

        <router-link
            v-for="link in pageLinks"
            :key="link.page"
            :to="{path: path, query: queryParams(link.page)}"
            class="page"
            :class="{current: link.current}"
            v-on:click.native="scroll"
        >
            {{ link.page }}
        </router-link>

        <router-link
            v-if="page != total"
            :to="{path: path, query: queryParams(parseInt(page) + 1)}"
            class="page"
            v-on:click.native="scroll"
        >
            >
        </router-link>
    </div>
</template>

<script>
    import { scrollTo } from '@/helper'

    export default {
        name: 'Pagination',

        props: [
            'path',
            'total',
            'page',
            'scrollAnchor'
        ],

        computed: {
            pageLinks() {
                var links = []
                for(var i = 1; i <= this.total; i++) {
                    links.push({
                        page: i,
                        current: i == this.page
                    })
                }
                return links
            }
        },

        methods: {
            scroll() {
                scrollTo(this.scrollAnchor)
            },

            queryParams(page) {
                return {
                    ...this.$route.query,
                    page: page
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    .pagination {
        background: white;
        color: $timberGreen;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 30px;

        .page {
            color: inherit;
            margin: 4px;
            font-size: getFontSize('mediumText', 'desktop');

            &.current {
                text-decoration: underline;
            }
        }
    }
</style>
